import { HashtagTowerSpec } from "../../Definitions/TowerSpecifications";
import { Tower } from "./Tower";
import { FlyingEnemy } from "../Enemies/FlyingEnemy";

export class HashtagTower extends Tower {
  constructor(map: string[], notifyUI: () => void) {
    super(map, HashtagTowerSpec, notifyUI);
  }

  canPlace() {
    const isOnGrass = this.map[this.tilePosition.y][this.tilePosition.x] === " ";

    return isOnGrass && super.canPlace();
  }

  getEnemiesInRange() {
    const enemiesInRange = super.getEnemiesInRange();
    return enemiesInRange.filter((en) => typeof en.enemy !== typeof FlyingEnemy);
  }
}
