import { createContext } from "react";
import { LeaderboardEntry, UserInfo } from "./models";

export const DataContext = createContext<{
  leaderboardEntries: LeaderboardEntry[];
  userInfo: UserInfo;
  fetchLeaderboardEntries(): void;
  getPlayerName(email: string | undefined): string;
}>({
  leaderboardEntries: [],
  getPlayerName: () => "",
  fetchLeaderboardEntries: () => {},
  userInfo: { nickname: "", email: "", firstname: "", lastname: "" },
});
