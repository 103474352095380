import { Map } from "../lib/models";

export const hardMap: Map = {
  name: "Hard",
  layout: [
    "~~~~~~~~~^^^^^^^    ",
    "~~~~~~~~~^^^^^^^    ",
    "~~~~~~xxxxxxxx^^    ",
    "~~~~~~x~~    x^^    ",
    "~~~~xxx~~    x      ",
    "~~~~x~~~~    x      ",
    "~~xxx~~~~    x      ",
    "~~x~~~~~~    x      ",
    "xxx~~~~~~    x      ",
    "~~~~~~~~~    x      ",
    "~~~~~~~~~    x      ",
    "           ^^x^^ xxx",
    "           ^^x^^ x  ",
    "           ^^xxxxx  ",
    "           ^^^^^    ",
  ],
};

export const normalMap: Map = {
  name: "Normal",
  layout: [
    "         ~~~~~~~~~~~",
    "         ~~~~xxxxxxx",
    "         ~~~~x~~^^^^",
    "             x ~^^^^",
    "             xxxxx  ",
    "               ~ x  ",
    "xxxxxxxxxxx    ~ x  ",
    "          x    ~ x  ",
    "      xxxxx    ~ x  ",
    "      x ^^^    ~ x  ",
    "      x  ^^  xxxxx  ",
    "      x      x ~    ",
    "      xxxxxxxx ~~~~~",
    "               ~~~~~",
    "               ~~~~~",
  ],
};

export const easyMap: Map = {
  name: "Easy",
  layout: [
    "        ~~~~~    ^^^",
    " xxxxxx ~~xxxxxxxx^^",
    " x    x ~~x~~   ^x^^",
    " x    xxx~x~~   ^x^^",
    " xxxx   xxx~~    x  ",
    "    x   ~~~~~    x  ",
    "    xxxxxxxxxxxx xx ",
    "               x  x ",
    "xxxxxxxxxx     x  x ",
    "         xxxxxxx  x ",
    "  xxxxx           x ",
    "  x~~~xx          x ",
    "  x~~~ xxxxxxxxxxxx ",
    "  x~~~              ",
    "  xxxxxxxxxxxxxxxxxx",
  ],
};
