import { useEffect, useState } from "react";
import { LeaderboardEntry, UserInfo } from "./lib/models";
import { getUserInfo } from "./lib/authenticationHandler";
import { LoginScreen } from "./loginScreen";
import { TowerDefense } from "./TowerDefense";
import { clearCredentials } from "./lib/credentialsHandler";
import { DataContext } from "./lib/contexts";
import { doFetch, leaderboardEntryComparator } from "./lib/functions";
import { APIResources } from "./lib/definitions";

function App() {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isWorking, setIsWorking] = useState(false);
  const [allUsers, setAllUsers] = useState<UserInfo[]>([]);
  const [leaderboardEntries, setLeaderboardEntries] = useState<LeaderboardEntry[]>([]);

  useEffect(() => {
    updateUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      fetchLeaderboardEntries();
      doFetch(
        "GET",
        "https://api.ckal.dk/users",
        setAllUsers,
        () => alert("Fetching all users: An error occurred"),
        undefined,
        undefined,
        true
      );
    }
  }, [userInfo]);

  function fetchLeaderboardEntries() {
    doFetch(
      "GET",
      APIResources.Leaderboard,
      (entries) => setLeaderboardEntries(entries.sort(leaderboardEntryComparator)),
      () => alert("Fetching leaderboard: An error occurred")
    );
  }

  async function updateUserInfo() {
    try {
      setUserInfo(await getUserInfo());
    } catch {}
  }

  function getPlayerName(email: string | undefined) {
    const player = allUsers.find((u) => u.email === email);

    if (!player) {
      return email || "No player name";
    }

    return player.firstname + " " + player.lastname;
  }

  return (
    <div style={{ height: "calc(100vh - 48px)", position: "relative" }}>
      {!userInfo ? (
        <LoginScreen onLogin={updateUserInfo} isWorking={isWorking} setIsWorking={setIsWorking} />
      ) : (
        <DataContext.Provider value={{ getPlayerName, fetchLeaderboardEntries, leaderboardEntries, userInfo }}>
          <TowerDefense userInfo={userInfo} />
        </DataContext.Provider>
      )}

      {userInfo && (
        <div style={{ position: "absolute", left: "24px", bottom: "16px", color: "lightgray" }}>
          Logged in as {userInfo.email}
          <span
            style={{ marginLeft: "24px", cursor: "pointer", textDecoration: "underline" }}
            onClick={async () => {
              await clearCredentials();
              setUserInfo(undefined);
            }}
          >
            Logout
          </span>
        </div>
      )}
      <div
        style={{
          position: "absolute",
          bottom: "16px",
          right: "24px",
          color: "lightgray",
          fontWeight: "bold",
          fontSize: "24px",
        }}
        onClick={() => window.location.reload()}
      >
        {process.env.REACT_APP_VERSION}
      </div>
    </div>
  );
}

export default App;
