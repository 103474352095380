import { GiTwoCoins } from "react-icons/gi";
import { GrClose, GrNext, GrPrevious } from "react-icons/gr";
import { BsFillHeartFill } from "react-icons/bs";
import { ActualTower, CANVAS_HEIGHT, TargetingMode } from "../lib/definitions";
import { WaveSection } from "./waveSection";
import { TowerList } from "./towerList";
import { Tower } from "../GameObjects/Towers/Tower";
import { TowerSection } from "./towerSection";
import { waves } from "../Definitions/Waves";
import { Button } from "./button";
import { useState } from "react";
import { gameStats } from "../TowerDefense";
import { ticksToPrettyTime } from "../lib/functions";
import { Map } from "../lib/models";
import { easyMap, hardMap, normalMap } from "../Definitions/Maps";

interface ControlPanelProps {
  money: number;
  points: number;
  health: number;
  waveNumber: number;
  ticks: number;
  selectedTower: Tower | undefined;
  isPaused: boolean;
  isFast: boolean;
  targetingMode: TargetingMode;
  pauseDisabled: boolean;
  requestForPause: boolean;
  nextWaveDisabled: boolean;
  map: Map;
  onStartGame(): void;
  setMap(map: Map): void;
  selectTowerToPlace(tower: ActualTower): void;
  clearSelectedTower(): void;
  notifyUI(): void;
  startNextWave(): void;
  showLeaderboard(): void;
}

export function ControlPanel(props: ControlPanelProps) {
  const [hasPressedStart, setHasPressedStart] = useState(false);

  return (
    <div
      style={{
        marginLeft: "24px",
        background: "lightgray",
        height: CANVAS_HEIGHT,
        fontSize: "24px",
        width: "256px",
        overflow: "hidden",
        userSelect: "none",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
        <Button style={{ marginRight: "8px" }} onClick={props.showLeaderboard}>
          Leaderboard
        </Button>
        <Button onClick={() => window.location.reload()}>Restart</Button>
      </div>
      {props.isPaused && !hasPressedStart ? (
        <div style={{ marginTop: "200px" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <GrPrevious
              style={{ cursor: "pointer" }}
              onClick={() =>
                props.setMap(props.map.name === "Normal" ? easyMap : props.map.name === "Easy" ? hardMap : normalMap)
              }
            />
            <div style={{ margin: "0 18px", textAlign: "center", width: 100 }}>{props.map.name}</div>
            <GrNext
              style={{ cursor: "pointer" }}
              onClick={() =>
                props.setMap(props.map.name === "Normal" ? hardMap : props.map.name === "Hard" ? easyMap : normalMap)
              }
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 48 }}>
            <Button
              style={{ height: "60px" }}
              onClick={() => {
                setHasPressedStart(true);
                gameStats.isPaused = !gameStats.isPaused;
                props.onStartGame();
              }}
            >
              Start Game
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div style={{ padding: "8px 24px 0" }}>
            <div style={{ fontWeight: "bold", textAlign: "center", fontSize: "30px" }}>{props.points}</div>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8x" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {props.money}
                <GiTwoCoins style={{ marginLeft: "8px", marginTop: "2px", color: "black" }} />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {props.health}
                <BsFillHeartFill style={{ marginLeft: "8px", marginTop: "2px", color: "red" }} />
              </div>
            </div>
            <div style={{ borderTop: "2px solid #ababab", margin: "12px 0 6px" }} />
            <div
              style={{
                fontSize: "20px",
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "bold",
                margin: "0 8px 8px",
              }}
            >
              <div style={{ whiteSpace: "nowrap" }}>
                Wave {props.waveNumber + 1}/{waves.length}
              </div>
              <div>{ticksToPrettyTime(props.ticks, true)}</div>
            </div>
            <WaveSection
              waveNumber={props.waveNumber}
              pauseDisabled={props.pauseDisabled}
              nextWaveDisabled={props.nextWaveDisabled}
              isPaused={props.isPaused}
              isFast={props.isFast}
              requestForPause={props.requestForPause}
              startNextWave={props.startNextWave}
            />
          </div>
          <div>
            <div style={{ borderTop: "2px solid #ababab", margin: "12px 24px 0" }} />
            <div
              style={{
                fontSize: "20px",
                marginTop: "8px",
                fontWeight: "bold",
                textAlign: "center",
                position: "relative",
              }}
            >
              {props.selectedTower && (
                <div
                  style={{
                    position: "absolute",
                    left: "24px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "18px",
                    height: "100%",
                  }}
                >
                  <GrClose style={{ cursor: "pointer" }} onClick={props.clearSelectedTower} />
                </div>
              )}
              {props.selectedTower ? "Tower Info" : "Towers"}
            </div>
            <div style={{ height: "434px", overflow: "auto", padding: "0 12px" }}>
              {props.selectedTower ? (
                <TowerSection
                  targetingMode={props.targetingMode}
                  selectedTower={props.selectedTower}
                  clearSelectedTower={props.clearSelectedTower}
                />
              ) : (
                <TowerList map={props.map} selectTower={props.selectTowerToPlace} notifyUI={props.notifyUI} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
