import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  calculatePathFromLevel,
  drawBackground,
  drawAndCleanupObjects,
  getMousePos,
  pixelsToTile,
} from "./lib/canvasFunctions";
import { ControlPanel } from "./components/controlPanel";
import {
  Point,
  ActualTower,
  ActualProjectile,
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  TICK_DURATION,
  TargetingMode,
  CANVAS_COLUMNS,
  CANVAS_ROWS,
  APIResources,
  LF_LAST_MAP,
} from "./lib/definitions";
import { Enemy } from "./GameObjects/Enemies/Enemy";
import { waves } from "./Definitions/Waves";
import { Tower } from "./GameObjects/Towers/Tower";
import { easyMap, hardMap, normalMap } from "./Definitions/Maps";
import { Map, UserInfo } from "./lib/models";
import { GameOverPane } from "./components/gameOverPane";
import { doFetch } from "./lib/functions";
import { DataContext } from "./lib/contexts";
import { PlainLeaderboard } from "./components/plainLeaderboard";
import localforage from "localforage";

let tick = 0;
let timeTicks = 0;
export const enemiesManagers: { enemies: Enemy[]; waveNumber: number }[] = [];
export const projectiles: ActualProjectile[] = [];
export const towers: ActualTower[] = [];
let hoveredTile: Point = { x: -1, y: -1 };
export let gameStats = {
  money: 10,
  points: 0,
  health: 100,
  isPaused: true,
  requestForPause: false,
  isFast: false,
  waveHealth: waves[0].amount * waves[0].hp,
};
let waveNumber = 0;
let waveManagers = [{ waveNumber: 0, wave: waves[0], enemiesSpawned: 0, waveTicks: (-3 * 1000) / (TICK_DURATION * 2) }];
let gameover = false;
let won = false;

export function allEnemies() {
  return enemiesManagers.reduce<Enemy[]>((prev, curr) => prev.concat(...curr.enemies), []);
}

interface TowerDefenseProps {
  userInfo: UserInfo;
}

export function TowerDefense(props: TowerDefenseProps) {
  const [map, setMap] = useState(normalMap);
  const [moneyState, setMoneyState] = useState(0);
  const [pointsState, setPointsState] = useState(0);
  const [healthState, setHealthState] = useState(0);
  const [waveNumberState, setWaveNumberState] = useState(0);
  const [towerToPlace, setTowerToPlace] = useState<ActualTower>();
  const [selectedTower, setSelectedTower] = useState<Tower>();
  const [gameOver, setGameOver] = useState(false);
  const [cursorPointer, setCursorPointer] = useState(false);
  const [isPausedState, setIsPausedState] = useState(false);
  const [isFastState, setIsFastState] = useState(false);
  const [targetingModeState, setTargetingModeState] = useState<TargetingMode>("Weakest");
  const [pauseDisabledState, setPauseDisabledState] = useState(false);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [requestForPauseState, setRequestForPauseState] = useState(false);
  const [nextWaveDisabledState, setNextWaveDisabledState] = useState(false);
  const [ticksState, setTicksState] = useState(0);
  // eslint-disable-next-line
  const [_, setNotifyFlip] = useState(false);

  const { fetchLeaderboardEntries } = useContext(DataContext);

  const path = useMemo(() => calculatePathFromLevel(map.layout), [map]);

  const clearTowers = useCallback(() => {
    if (towerToPlace) {
      towerToPlace.shouldDraw = false;
    }
    if (selectedTower) {
      selectedTower.setDrawRange(false);
    }
    setTowerToPlace(undefined);
    setSelectedTower(undefined);
  }, [towerToPlace, selectedTower]);

  const postScore = useCallback(() => {
    doFetch(
      "POST",
      APIResources.Score,
      fetchLeaderboardEntries,
      () => alert("Posting score: An error occurred"),
      undefined,
      {
        won: won,
        wave: waveNumber + 1,
        score: gameStats.points,
        ticks: timeTicks,
        lives: gameStats.health,
        mapName: map.name,
      }
    );
  }, [fetchLeaderboardEntries, map.name]);

  useEffect(() => {
    localforage.getItem(LF_LAST_MAP, (_, val) => {
      if (val) {
        const lastMap = [easyMap, normalMap, hardMap].find((m) => m.name === val);
        if (lastMap) {
          setMap(lastMap);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (gameover) {
      return;
    }

    const canvas2 = document.getElementById("background-layer") as HTMLCanvasElement;
    const bg = canvas2.getContext("2d");

    let id: NodeJS.Timeout;

    if (bg) {
      drawBackground(bg, map.layout);
    }

    const canvas = document.getElementById("game-layer") as HTMLCanvasElement;
    const game = canvas.getContext("2d");

    canvas.onmousemove = (mouseEvent) => {
      if (gameStats.isPaused) {
        return;
      }

      hoveredTile = pixelsToTile(getMousePos(canvas, mouseEvent));

      if (
        hoveredTile.x < 0 ||
        CANVAS_COLUMNS - 1 < hoveredTile.x ||
        hoveredTile.y < 0 ||
        CANVAS_ROWS - 1 < hoveredTile.y
      ) {
        return;
      }

      const existingTowerIndex = towers.findIndex(
        (tower) =>
          tower !== towerToPlace &&
          tower.getTilePosition().x === hoveredTile.x &&
          tower.getTilePosition().y === hoveredTile.y
      );
      if (towerToPlace) {
        towerToPlace.setTilePosition(hoveredTile);
        towerToPlace.setDrawRangeRed(!towerToPlace.canPlace());
      }
      if (existingTowerIndex !== -1) {
        setCursorPointer(true);
      } else {
        setCursorPointer(false);
      }
    };

    canvas.onmousedown = (mouseEvent) => {
      if (mouseEvent.button === 2 || gameStats.isPaused) {
        return;
      }

      const clickedTile = pixelsToTile(getMousePos(canvas, mouseEvent));
      const existingTowerIndex = towers.findIndex(
        (tower) =>
          tower !== towerToPlace &&
          tower.getTilePosition().x === clickedTile.x &&
          tower.getTilePosition().y === clickedTile.y
      );

      if (towerToPlace) {
        if (towerToPlace.canPlace()) {
          towerToPlace.place();
          setTowerToPlace(undefined);
        }
      } else if (existingTowerIndex !== -1) {
        clearTowers();
        setSelectedTower(towers[existingTowerIndex]);
        towers[existingTowerIndex].setDrawRange(true);
      } else if (selectedTower) {
        selectedTower.setDrawRange(false);
        setSelectedTower(undefined);
      }
    };

    if (game) {
      id = setInterval(() => {
        if (gameover) return;

        if (gameStats.isPaused !== isPausedState) setIsPausedState(gameStats.isPaused);
        if (gameStats.isFast !== isFastState) setIsFastState(gameStats.isFast);

        if (gameStats.isPaused) {
          return;
        }

        if (!gameStats.isFast && tick % 2 === 0) {
          tick++;
          return;
        }

        game.clearRect(0, 0, canvas.width, canvas.height);

        if (waveManagers.length === 0 && enemiesManagers.length === 0 && waveNumber < waves.length - 1) {
          projectiles.forEach((proj) => (proj.shouldDraw = false));
          if (gameStats.requestForPause) {
            gameStats.isPaused = true;
            gameStats.requestForPause = false;
          }
          startNextWave(true);
          setNextWaveDisabledState(true);
          setTimeout(() => setNextWaveDisabledState(false), 2500);
        }

        for (let i = waveManagers.length - 1; i >= 0; i--) {
          const { wave, enemiesSpawned, waveTicks, waveNumber } = waveManagers[i];
          if (wave.amount - enemiesSpawned === 0) {
            waveManagers.splice(i, 1);
          } else if (waveTicks >= 0 && waveTicks % Math.round(1000 / (wave.frequency * TICK_DURATION * 2)) === 0) {
            let em = enemiesManagers.find((em) => em.waveNumber === waveNumber);
            if (!em) {
              enemiesManagers.push({ enemies: [], waveNumber: waveNumber });
            }

            em = enemiesManagers.find((em) => em.waveNumber === waveNumber)!;

            em.enemies.push(new wave.enemyType(path, wave.hp, wave.velocity, wave.damage, wave.reward));
            waveManagers[i].enemiesSpawned++;
          }
        }

        drawAndCleanupObjects(game, towers);
        enemiesManagers.forEach((em) => drawAndCleanupObjects(game, em.enemies));
        drawAndCleanupObjects(game, projectiles);

        for (let i = enemiesManagers.length - 1; i >= 0; i--) {
          if (enemiesManagers[i].enemies.length === 0) {
            enemiesManagers.splice(i, 1);
          }
        }

        [...towers, ...allEnemies(), ...projectiles].forEach((obj) => obj.tick());

        if (gameStats.money !== moneyState) setMoneyState(gameStats.money);
        if (gameStats.points !== pointsState) setPointsState(gameStats.points);
        if (gameStats.health !== healthState) setHealthState(gameStats.health);
        if (gameStats.requestForPause !== requestForPauseState) setRequestForPauseState(gameStats.requestForPause);
        if (waveNumber !== waveNumberState) setWaveNumberState(waveNumber);
        setPauseDisabledState(allEnemies().length > 0);

        const targetingMode = selectedTower?.getTargetingMode();
        if (targetingMode && targetingMode !== targetingModeState) setTargetingModeState(targetingMode);

        if (
          gameStats.health <= 0 ||
          (waveNumber === waves.length - 1 && allEnemies().length === 0 && waveManagers.length === 0)
        ) {
          clearInterval(id);
          tick--;
          setPointsState(gameStats.points);
          setGameOver(true);

          gameover = true;
          won = gameStats.health > 0;

          postScore();
        }

        tick++;
        timeTicks += 2;

        if (timeTicks % 200 === 0) {
          setTicksState(timeTicks);
        }

        waveManagers.forEach((wm) => wm.waveTicks++);
      }, TICK_DURATION);
    }

    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [
    towerToPlace,
    moneyState,
    healthState,
    pointsState,
    waveNumberState,
    selectedTower,
    isPausedState,
    isFastState,
    targetingModeState,
    requestForPauseState,
    map,
    path,
    postScore,
    clearTowers,
  ]);

  function startNextWave(withDelay?: boolean) {
    waveNumber++;
    waveManagers.push({
      waveNumber: waveNumber,
      wave: waves[waveNumber],
      waveTicks: withDelay ? (-3 * 1000) / (TICK_DURATION * 2) : 0,
      enemiesSpawned: 0,
    });
    gameStats.waveHealth += waves[waveNumber].amount * waves[waveNumber].hp;
  }

  function notifyUI() {
    setNotifyFlip((notifyFlip) => !notifyFlip);
  }

  function changeMap(map: Map) {
    localforage.setItem(LF_LAST_MAP, map.name);
    setMap(map);
  }

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "48px",
          outline: "none",
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            if (towerToPlace) {
              towers.splice(towers.indexOf(towerToPlace), 1);
              setTowerToPlace(undefined);
            }

            if (selectedTower) {
              selectedTower.setDrawRange(false);
              setSelectedTower(undefined);
            }
          }
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          if (towerToPlace) {
            towers.splice(towers.indexOf(towerToPlace), 1);
            setTowerToPlace(undefined);
          }

          if (selectedTower) {
            selectedTower.setDrawRange(false);
            setSelectedTower(undefined);
          }
        }}
        tabIndex={0}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              position: "relative",
              height: CANVAS_HEIGHT,
              width: CANVAS_WIDTH,
            }}
          >
            <canvas id="background-layer" height={CANVAS_HEIGHT} width={CANVAS_WIDTH} style={{ zIndex: 1 }} />
            <canvas
              id="game-layer"
              height={CANVAS_HEIGHT}
              width={CANVAS_WIDTH}
              style={{ zIndex: 2, cursor: cursorPointer ? "pointer" : undefined }}
            />
          </div>
          <ControlPanel
            money={moneyState}
            points={pointsState}
            health={healthState}
            ticks={ticksState}
            setMap={changeMap}
            map={map}
            waveNumber={waveNumberState}
            selectedTower={selectedTower}
            pauseDisabled={pauseDisabledState}
            nextWaveDisabled={nextWaveDisabledState}
            clearSelectedTower={clearTowers}
            isPaused={isPausedState}
            isFast={isFastState}
            startNextWave={startNextWave}
            targetingMode={targetingModeState}
            requestForPause={requestForPauseState}
            onStartGame={() => setShowLeaderboard(false)}
            showLeaderboard={() => setShowLeaderboard(!showLeaderboard)}
            selectTowerToPlace={(tower: ActualTower) => {
              if (gameStats.isPaused) {
                return;
              }
              clearTowers();
              setTowerToPlace(tower);
              towers.push(tower);
            }}
            notifyUI={notifyUI}
          />
        </div>
      </div>
      {(gameOver || showLeaderboard) && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            zIndex: 100,
          }}
        >
          {gameOver ? (
            <GameOverPane
              score={pointsState}
              lives={healthState}
              timeTicks={timeTicks}
              wave={waveNumberState}
              won={won}
              mapName={map.name}
            />
          ) : (
            <PlainLeaderboard
              closeLeaderboard={() => setShowLeaderboard(false)}
              mapName={map.name}
              setMap={changeMap}
            />
          )}
        </div>
      )}
    </div>
  );
}
