import { BasicTowerSpec } from "../../Definitions/TowerSpecifications";
import { Tower } from "./Tower";

export class BasicTower extends Tower {
  constructor(mapLayout: string[], notifyUI: () => void) {
    super(mapLayout, BasicTowerSpec, notifyUI);
  }

  canPlace() {
    const isOnGrass = this.map[this.tilePosition.y][this.tilePosition.x] === " ";

    return isOnGrass && super.canPlace();
  }
}
