import { gameStats } from "../TowerDefense";
import { towerSpecifications } from "../Definitions/TowerSpecifications";
import { Tower } from "../GameObjects/Towers/Tower";
import { TowerCard } from "./towerCard";
import { Map } from "../lib/models";

interface TowerListProps {
  selectTower(tower: Tower): void;
  notifyUI: () => void;
  map: Map;
}

export function TowerList(props: TowerListProps) {
  return (
    <div
      style={{
        marginTop: "8px",
        display: "grid",
        rowGap: "8px",
      }}
    >
      {towerSpecifications.map((spec) => (
        <TowerCard
          key={spec.name}
          onClick={
            spec.prices[0] <= gameStats.money
              ? () => props.selectTower(new spec.towerConstructor(props.map.layout, props.notifyUI))
              : undefined
          }
          spec={spec}
        />
      ))}
      <div style={{ height: "24px" }} />
    </div>
  );
}
