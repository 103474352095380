import React, { CSSProperties, useContext, useMemo } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import { FaCrown, FaSkullCrossbones } from "react-icons/fa";
import { GrNext, GrPrevious } from "react-icons/gr";
import { easyMap, hardMap, normalMap } from "../Definitions/Maps";
import { DataContext } from "../lib/contexts";
import { ticksToPrettyTime, toDateAndTime } from "../lib/functions";
import { Map } from "../lib/models";

interface LeaderboardSegmentProps {
  mapName: string;
  setMap?(map: Map): void;
  height?: number;
}

export function LeaderboardSegment(props: LeaderboardSegmentProps) {
  const { getPlayerName, leaderboardEntries, userInfo } = useContext(DataContext);

  function getTextStyle(email: string): CSSProperties {
    return { textDecoration: email === userInfo.email ? "underline" : undefined };
  }

  const entries = useMemo(
    () => leaderboardEntries.filter((le) => le.mapName === props.mapName),
    [props.mapName, leaderboardEntries]
  );

  return (
    <div style={{ marginRight: -20, userSelect: "none" }}>
      <div
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          lineHeight: "24px",
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "1fr 250px 1fr",
          columnGap: 12,
        }}
      >
        <div />
        Leaderboard - {props.mapName}
        {props.setMap && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <GrPrevious
              style={{ cursor: "pointer", marginRight: 8 }}
              onClick={() =>
                props.setMap!(props.mapName === "Normal" ? easyMap : props.mapName === "Easy" ? hardMap : normalMap)
              }
            />
            <GrNext
              style={{ cursor: "pointer" }}
              onClick={() =>
                props.setMap!(props.mapName === "Normal" ? hardMap : props.mapName === "Hard" ? easyMap : normalMap)
              }
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "min-content auto min-content min-content min-content min-content",
          columnGap: "32px",
          marginTop: "24px",
          fontWeight: "bold",
          fontSize: "24px",
          lineHeight: "24px",
          rowGap: 12,
          maxHeight: props.height || 450,
          paddingRight: 20,
          overflow: "scroll",
          minWidth: 400,
        }}
      >
        {entries.length === 0 ? (
          <div style={{ gridColumn: "span 6", fontWeight: "normal" }}>No leaderboard entries for this map yet...</div>
        ) : (
          entries.map((le, i) => (
            <React.Fragment key={le.player}>
              <div>{i + 1 + "."}</div>
              <div style={getTextStyle(le.player)}>{getPlayerName(le.player)}</div>
              <div style={{ whiteSpace: "nowrap", textAlign: "end" }}>
                {le.won ? ticksToPrettyTime(le.ticks) : le.score}
              </div>
              <div style={{ whiteSpace: "nowrap", textAlign: "end" }}>
                {le.lives}
                <BsFillHeartFill style={{ marginLeft: "8px", marginBottom: "-1px", color: "red", fontSize: "18px" }} />
              </div>
              <div style={{ whiteSpace: "nowrap", textAlign: "end" }}>
                {le.wave}
                {le.won ? (
                  <FaCrown style={{ marginLeft: "8px", fontSize: "18px" }} />
                ) : (
                  <FaSkullCrossbones style={{ marginLeft: "8px", fontSize: "18px" }} />
                )}
              </div>
              <div style={{ whiteSpace: "nowrap", textAlign: "end" }}>{toDateAndTime(le.timestamp, true, true)}</div>
            </React.Fragment>
          ))
        )}
        <div style={{ gridColumn: "span 6" }} />
      </div>
    </div>
  );
}
